<template>
	<div class="person-info">
		<div class="avatar">
			<!-- <el-upload
			    :action="uploadUrl"
			    list-type="picture-card"
			    name="image"
			    :before-upload="beforeAvatarUpload"
			    :on-success="handleAvatarSuccess"
			    :show-file-list="false"
			    ref="cover">
			  <img v-if="imageUrl" :src="imageUrl" class="avatar">
			  <i v-else slot="default" class="el-icon-plus"></i>
			</el-upload> -->
			<el-upload
			    class="avatar-uploader"
			    :action="uploadUrl"
				name="image"
			    :show-file-list="false"
			    :on-success="handleAvatarSuccess"
			    :before-upload="beforeAvatarUpload"
				ref="cover">
			  <!-- <img v-if="ruleForm.idCardImg" :src="ruleForm.idCardImg" class="avatar">
			  <div class="el-upload__tip">身份证/护照（正面）</div> -->
			  <img v-if="ruleForm.image  == ''" class="avatar-img" src="https://fuhuiduoduo.axa2.com/upload/static/icon-touxiang@2x(1).png" alt="">
			  <img v-if="ruleForm.image != ''" class="avatar-img" :src="ruleForm.image" alt="">
			</el-upload>
			
			<!-- <div class="avatar-name">{{myInfo.name}}</div> -->
		</div>
		<div class="form">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item :label="$t('stagename')" prop="name">
					<el-input v-model="ruleForm.name" :placeholder="$t('enterstagename')"></el-input>
			 </el-form-item>
				<el-form-item class="sex" :label="$t('sex')" prop="radio">
					<el-radio-group v-model="ruleForm.radio">
						<el-radio :label="0">{{$t('man')}}</el-radio>
						<el-radio :label="1">{{$t('woman')}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="$t('personalprofile')" prop="desc">
					<el-input type="textarea" v-model="ruleForm.desc" :placeholder="$t('enterpersonalprofile')" maxlength="500" show-word-limit></el-input>
				</el-form-item>
				<el-form-item class="button-finish">
					<el-button @click="modifyMyinfo" type="primary">{{$t('finished')}}</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		editMyinfo,
		myInfo
	} from "@/api/Mine";
	export default {
		props: {},
		computed: {},
		components: {},
		data() {
			return {
				
				ruleForm: {
					radio: 0,
					desc: '',
					name: '',
					addr: '',
					image: '',
				},
				rules: {
					name: [{
						message: this.$t("enterstagename"),
						trigger: 'change'
					}],
					radio: [{
						message: this.$t("selectgender"),
						trigger: 'blur'
					}],
					desc: [{
				 	message: this.$t("inputbriefintroduction"),
						trigger: 'blur'
				 }]
				},
				myInfo: {},
				uploadUrl: process.env.VUE_APP_UPLOAD_IMAGE_URL,
				// uploadUrl: 'http://ht.7keymusic.com/api/uploadImage',
				image1: ''
			}
		},
		created() {
			this.getMyInfo()
			// this.myInfo = JSON.parse(localStorage.getItem('myInfo'))
			
			// console.log(this.myInfo.name,'name')
		},
		methods: {
			// 我的信息
			getMyInfo() {
				myInfo({}).then((res) => {
						console.log(res, '我的信息成功');
						this.myInfo = res.data
						this.ruleForm.name = this.myInfo.name
						this.ruleForm.desc = this.myInfo.introduce
						this.ruleForm.radio = this.myInfo.sex
						this.ruleForm.image = this.myInfo.image
						// localStorage.setItem('myInfo', JSON.stringify(this.myInfo))
						this.$store.state.myInfo = res.data
						// localStorage.setItem('myInfo', JSON.stringify(this.infoObj))
					})
					.catch((err) => {
						console.log(err, '我的信息失败');
					});
			},
			// 修改个人信息
			modifyMyinfo(){
				console.log(this.ruleForm)
				editMyinfo({
					sign: this.ruleForm.name,//署名
					sex: this.ruleForm.radio,//0男 1女
					image: this.image1,//头像
					introduce: this.ruleForm.desc,//个人介绍
					addr: this.ruleForm.addr,//地址
				}).then((res) => {
				      console.log(res,'修改个人信息成功');
					  this.$message.success('修改成功');
					  this.getMyInfo()
				    })
				    .catch((err) => {
				      console.log(err,'获取推荐制作人列表失败');
				    });
			},
			handleAvatarSuccess(res, file) {
			  console.log(file)
			  // this.ruleForm.image = process.env.VUE_APP_FILE_URL + res.data.url;
			  this.ruleForm.image = URL.createObjectURL(file.raw);
			  this.image1 = res.data.url
			  // this.ruleForm.image = this.uploadUrl +'/'+ res.data.url
			  // this.image1 = res.data.url
			  console.log(this.ruleForm.image,this.image1,'process.env.VUE_APP_FILE_PATH')
			},
			beforeAvatarUpload(file) {
			  // const isJPG = file.type === 'image/jpeg';
			  // const isLt2M = file.size / 1024 / 1024 < 2;
			  //
			  // if (!isJPG) {
			  //   this.$message.error('上传头像图片只能是 JPG 格式!');
			  // }
			  // if (!isLt2M) {
			  //   this.$message.error('上传头像图片大小不能超过 2MB!');
			  // }
			  // return isJPG && isLt2M;
			  return true
			}
			
		}
	}
</script>

<style lang="less" scoped>
	.person-info {
		width: 100%;
		height: 650px;
		background: @recommendMusicianListBGColor;
		border-radius: 6px;
		backdrop-filter: blur(50px);
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.avatar{
			// width: 100%;
			margin-bottom: 40px;
			display: flex;
			flex-direction: column;
			// justify-content: center;
			align-items: center;
			.avatar-img {
				width: 120px;
				height: 120px;
				border-radius: 50%;
			}
			.avatar-name{
				height: 22px;
				font-size: 16px;
				font-weight: 500;
				color: @recommendMusicianListTextColor;
				margin-top: 16px;
			}
		}
		.form {
			* {
				::-webkit-scrollbar {
					/*隐藏滚轮*/
					display: none;
				}
			}
			.demo-ruleForm {
				::v-deep .el-input {
					width: 400px;
					background: @recommendMusicianListBGColor;
					border: none;
				}

				::v-deep .el-form-item__label {
					color: @recommendMusicianListTextColor;
				}

				::v-deep .el-input__inner {
					width: 400px;
					background: rgba(34, 36, 38, 0.0500);
					border: none;
					color: @recommendMusicianListTextColor;
					&:hover {
					  border: 1px solid @popupCancelTextColor;
					}
				}

				::v-deep .el-form-item__label {
					color: @recommendMusicianListTextColor;
				}

				::v-deep .el-button--primary {
					width: 86px;
					height: 38px;
					background: #3370FF;
					border-radius: 4px;
					border: none;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					color: #FFFFFF;
				}

				::v-deep .el-input-group__append,
				.el-input-group__prepend {
					background: rgba(0, 0, 0, 0);
					border: none;
				}

				::v-deep .el-textarea {
					width: 400px !important;
					background: @recommendMusicianListBGColor;
				}

				::v-deep .el-textarea__inner {
					width: 400px !important;
					height: 128px;
					background: rgba(34, 36, 38, 0.0500);
					border: none;
					resize: none;
					color: @recommendMusicianListTextColor;
					&:hover {
					  border: 1px solid @popupCancelTextColor;
					}
				}

				::v-deep .el-input__count {
					background: rgba(0, 0, 0, 0);
				}

				.sex {
					::v-deep .el-radio-group {
						display: flex;
						// background: @recommendMusicianListBGColor;
					}

					::v-deep .el-radio {
						width: 76px;
						height: 42px;
						background: @personInfoFormColor;
						border-radius: 4px;
						display: flex;
						align-items: center;
						justify-content: center;
						&:hover {
						  border: 1px solid @popupCancelTextColor;
						  box-sizing: border-box;
						}
					}

					::v-deep .el-radio__inner {
						color: #999999;
						
						// background-color: rgba(34, 36, 38, 0.0500);
					}

					::v-deep .el-radio__input.is-checked .el-radio__inner {
						border-color: @personInfoFormColor;
						background-color: @recommendMusicianListTextColor;
					}

					::v-deep .el-radio__label {
						color: @recommendMusicianListTextColor;
					}

					::v-deep .el-radio__inner::after {
						width: 0.25rem;
						height: 0.25rem;
						border-radius: 100%;
						background-color: @personInfoRadioBgColor;
						content: "";
						position: absolute;
						left: 50%;
						top: 50%;
					}

					// ::v-deep .el-radio__input .is-checked{
					// 	background-color: #222426;
					// }
				}

				.button-finish {
					display: flex;
					// justify-content: center;
					padding-left: 71px;

					::v-deep .el-button {
						width: 140px;
						height: 38px;
						background: #3370FF;
						border-radius: 6px;
						
					}

				}

			}
		}
	}
</style>
